import { ElLoading, ClickOutside } from 'element-plus'
import { createPinia } from 'pinia'
import { calculator, formatDate, formatDatem, formatMoney,formatDatey, formatNumber, formatPercent, toMoney, createRouter } from '@enocloud/utils'

import type { App } from 'vue'

const Router = createRouter()
const Pinia = createPinia()

export default {
  install(app: App) {
    app.config.globalProperties.formatDate = formatDate
    app.config.globalProperties.formatDatem = formatDatem
    app.config.globalProperties.formatDatey = formatDatey
    app.config.globalProperties.formatMoney = formatMoney
    app.config.globalProperties.formatNumber = formatNumber
    app.config.globalProperties.formatPercent = formatPercent
    app.config.globalProperties.toMoney = toMoney
    app.config.globalProperties.calculator = calculator
    app.use(Pinia)
    app.use(Router)
    app.use(ElLoading)
    app.directive('ClickOutside', ClickOutside)
  }
}
