<template>
  <el-config-provider :locale="zhCn">
    <router-view v-slot="{ Component }">
      <component v-if="ready" :is="Component"></component>
    </router-view>

    <en-drawer v-model="store.download.visible" direction="btt" size="60%" title="导出列表">
      <flex-box>
        <template #default="{ height }">
          <en-table :data="store.download.data" :height="height" :loading="store.download.loading" pagination :paging="store.download.paging">
            <en-table-column label="操作">
              <template #default="{ row }: { row: EnocloudCommonDefinitions['ReportDto'] }">
                <en-button
                  type="primary"
                  link
                  :disabled="row.status?.code === 'P' || row.status?.code === 'I' || row.status?.code === 'C'"
                  @click="onDownloadClick(row)"
                  >下载</en-button
                >
                <en-button
                  type="primary"
                  link
                  :disabled="row.status?.code === 'P' || row.status?.code === 'I' || row.status?.code === 'C'"
                  @click="oPreviewClick(row)"
                  >预览</en-button
                >
              </template>
            </en-table-column>
            <en-table-column label="任务名称" prop="type.message"></en-table-column>
            <en-table-column label="导出时间">
              <template #default="{ row }: { row: EnocloudCommonDefinitions['ReportDto'] }">
                <span>{{ formatDate(row.preparedDatetime, true) }}</span>
              </template>
            </en-table-column>
            <en-table-column label="导出状态" prop="status.message"></en-table-column>
          </en-table>
        </template>
      </flex-box>

      <template #footer>
        <en-button type="primary" @click="store.refreshDownload">刷新</en-button>
        <en-button @click="store.closeDownload">关闭</en-button>
      </template>
    </en-drawer>
  </el-config-provider>
</template>

<script setup lang="ts">
import Cookies from 'universal-cookie'
import { onMounted, ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { zhCn } from '@enocloud/utils'
import { useStore } from '@enocloud/utils'

const cookies = new Cookies()
const store = useStore()
const route = useRoute()
const router = useRouter()

const ready = ref(false)

onMounted(async () => {
  const res = await fetch(import.meta.env.VITE_ENOCLOUD_URL + '/enocloud/sso/security/user', { credentials: 'include' }).then((res) => res.json())
  if (res.data[0]) {
    const user = res.data[0]
    const { token, tenant, branch, ssoUserId } = user
    cookies.set('SSO_USER_ID', ssoUserId, { path: '/' })
    cookies.set('SSO_TOKEN', token, { path: '/' })
    cookies.set('ENOCH_TENANT', tenant?.id, { path: '/' })
    cookies.set('ENOCH_BRANCH', branch?.id, { path: '/' })
    try {
      await store.changeUser()
      await store.changeAttribute()
      await store.changeOss()
    } finally {
      ready.value = true
    }
  } else {
    ready.value = true
    router.replace('/unlogin')
  }
})

watchEffect(() => {
  if (route.path === '/unlogin' && store.user.id) router.push('/')
})

const onDownloadClick = (row: EnocloudCommonDefinitions['ReportDto']) => {
  window.open(row.url)
}

const oPreviewClick = (row: EnocloudCommonDefinitions['ReportDto']) => {
  window.open(`https://view.officeapps.live.com/op/view.aspx?src=${row.url}`)
}
</script>

<style>
:root {
  font-size: 16px;
  line-height: 24px;
  --el-text-color-primary: #262626;
  color: var(--el-text-color-primary);
}

html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
