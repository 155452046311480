import { createApp } from 'vue'

import 'virtual:uno.css'

import 'element-plus/theme-chalk/src/message.scss'
import 'element-plus/theme-chalk/src/loading.scss'
import 'element-plus/theme-chalk/src/notification.scss'
import 'element-plus/theme-chalk/src/message-box.scss'

import App from '@container/app.vue'
import Plugins from '@container/plugins'

import './unocss.css'

const app = createApp(App).use(Plugins)

export default {
  mount() {
    app.mount('#app')
  },
  unmount() {
    app.unmount()
  }
}
